import { BusinessCategory, LessonLearned, LessonLearnedInstances, OpportunityRegister, Plant, RiskRegister, TemporalProjectPricingBlocks, TemporalProjectPricingPrices, TemporalProjectPricingTemplate, TemporalProjectPricingType } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { generateUUID } from "../../../utils/databaseAppUtils"
import { CardHeaderWithMenu } from "../../../components/panelv2/Panel2"
import { OEM } from "../../table_configuration/Counterparty"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../../components/menuv2/Menu"
import { Text, SegmentedControl, Badge, Alert, Checkbox } from "@mantine/core"
import { Counterparty, Employee } from "../../table_configuration/Entity"
import _ from 'lodash'
import { booleanOptions } from "../../table_configuration/CommonColumns"
import { BESSSpec, InverterSpec, ModuleSpec, TrackerSpec } from "../../table_configuration/EquipmentSpec"
import { formatDateDisplay } from "../../../utils/Dates"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"


const TextMaskNumber = makeTextMaskNumber({
    scale: 4
})

const makeLessonInstanceRecord = (lessonId) => {
    const record = LessonLearnedInstances.buildNewRecord()
    record[LessonLearnedInstances.columnSchema.oid] = generateUUID()
    record[LessonLearnedInstances.columnSchema.lessonLearnedId] = lessonId
    return record
}


export default function LessonsLearnedPanelProxy(props) {

    const businessCategories = props.data[BusinessCategory.buildId()]
    const projects = _.sortBy(props.data[Plant.buildId()], Plant.columnSchema.plantName)
    const employees = _.sortBy(props.data[Employee.buildId()], [Employee.columnSchema.firstName, Employee.columnSchema.lastName])
    const risks = props.data[RiskRegister.buildId()]
    const filteredRisks = props.selectedPlantId ? risks.filter(x => x[RiskRegister.columnSchema.projectId]===props.selectedPlantId) : risks
    const opportunities = props.data[OpportunityRegister.buildId()]
    const filteredOpportunities = props.selectedPlantId ? opportunities.filter(x => x[RiskRegister.columnSchema.projectId]===props.selectedPlantId) : opportunities
    const lessonsLearned = props.data[LessonLearned.buildId()].filter(x => x[LessonLearned.columnSchema.projectId]===props.selectedPlantId)
    const lessonsLearnedIds = lessonsLearned.map(x => x[LessonLearned.columnSchema.oid])
    const lessonLearnedInstances = props.data[LessonLearnedInstances.buildId()].filter(x => lessonsLearnedIds.includes(x[LessonLearnedInstances.columnSchema.lessonLearnedId]))

    return (
        <DialogProvider>
            <LessonsLearnedPanel
                initialLessonLearnedRecords={lessonsLearned}
                intialLessonLearnedInstanceRecords={lessonLearnedInstances}
                businessCategories={businessCategories}
                projects={projects}
                employees={employees}
                risks={filteredRisks}
                opportunities={filteredOpportunities}
                {...props}
            />
        </DialogProvider>
    )
}

function LessonsLearnedPanel({initialLessonLearnedRecords, intialLessonLearnedInstanceRecords, employees, businessCategories, projects, risks, opportunities, selectedPlant, ...props}) {

    const [
        lessonRecords, prepareLessonLog, updateLessonLog, addToLessonLog,
        deleteFromLessonLog, bulkOpOnLessonLog, mergeAndResetLessonLog, resetLessonLog, popFromLessonLog
    ] = useChangeLog(initialLessonLearnedRecords, LessonLearned)
    const [
        lessonInstanceRecords, prepareLessonInstanceLog, updateLessonInstanceLog, addToLessonInstanceLog,
        deleteFromLessonInstanceLog, bulkOpOnLessonInstanceLog, mergeAndResetLessonInstanceLog, resetLessonInstanceLog, popFromLessonInstanceLog
    ] = useChangeLog(intialLessonLearnedInstanceRecords, LessonLearnedInstances)
    const [isCreating, setIsCreating] = useState(false)
    
    const [selectedLessonId, setSelectedLessonId] = useState(null)
    const selectedLesson = selectedLessonId ? lessonRecords.find(x => x[LessonLearned.columnSchema.oid]===selectedLessonId) : null

    const filteredLessonInstances = lessonInstanceRecords.filter(x => x[LessonLearnedInstances.columnSchema.lessonLearnedId]===selectedLessonId)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [openDialog, closeDialog] = useDialog();

    /*const makeBlockName = (date) => {
        const displayDate = (!isNaN(date) && date) ? formatDateDisplay(date) : null
        const name = displayDate ? `${props.selectedPlant[Plant.columnSchema.plantName]} - ${displayDate}` : null
        return name
    }*/

    const updateLessonLogProxy = (update) => updateLessonLog(selectedLesson, update)
    /* Functions to create a new lesson */
    const addToLessonLogProxy = () => {
        const newLesson = LessonLearned.buildNewRecord()
        const lessonId = generateUUID()
        newLesson[LessonLearned.columnSchema.oid] = lessonId
        newLesson[LessonLearned.columnSchema.projectId] = props.selectedPlantId

        setSelectedLessonId(lessonId)
        bulkOpOnLessonLog([{
            method: "clear"
        }, {
            method: "insert", changes: [newLesson]
        }])
    }

    const createLesson = () => {
        const create = () => {
            addToLessonLogProxy()
            resetErrors()
            setIsCreating(true)
        }
        if (isCreating) {
            window.alert("You may only add one at a time.")
            return
        }
        if (prepareLessonLog().length > 0 || prepareLessonInstanceLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    create()
                }
            });
        }
        else create()
    }

    const selectLesson = (record) => {
        const lessonId = record[LessonLearned.columnSchema.oid]
        if (lessonId===selectedLessonId) return
        tryPurgeChanges(prepareLessonLog(), prepareLessonInstanceLog(), () => setSelectedLessonId(lessonId))
    }
    const tryPurgeChanges = (log1, log2, callback) => {
        const purge = () => {
            if (callback) callback()
            resetLessonLog()
            resetLessonInstanceLog()
            resetErrors()
            setIsCreating(false)
        }
        if (log1.length > 0 || log2.length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    purge()
                }
            });
        }
        else {
            purge()
        }
    }

    /* Functions to delete a risk/opportunity */
    const deleteLesson = (callback) => {
        openDialog({
            title: "Watch out!",
            body: <DialogContentText>Are you sure you want to delete the selected Lesson Learned and associated instances?</DialogContentText>,
            onAccept: () => {
                closeDialog()
                setSelectedLessonId(null)
                deleteFromLessonLog(selectedLesson)
                deleteFromLessonInstanceLog(filteredLessonInstances)
                setTimeout(() => {
                    callback({
                        onFail: () => {
                            resetErrors()
                            setSelectedLessonId(selectedLessonId)
                            popFromLessonLog()
                            popFromLessonInstanceLog(0)
                        }
                    })
                }, 100)
            }
        });
    }

    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                title="Lessons Learned"
                selectedId={selectedLessonId}
                records={_.sortBy(lessonRecords, (x) => x[LessonLearned.columnSchema.title])}
                recordIdCol={LessonLearned.columnSchema.oid}
                recordNameCol={LessonLearned.columnSchema.title}
                onSelect={selectLesson}
                onAdd={createLesson}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='api/precious/table/lesson_learned'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.lessonLearnedChangeLog,
                            checks: [{
                                checkColumn: LessonLearned.columnSchema.status,
                                checkFunction: "nullCheck",
                                errMessage: "Please select a status.",
                                path: [
                                    {name: LessonLearned.buildId(), type: "static"},
                                    {name: LessonLearned.columnSchema.status, type: "static"},
                                    {name: LessonLearned.columnSchema.oid, type: "eval"}
                                ]
                            }, {
                                checkColumn: LessonLearned.columnSchema.originStage,
                                checkFunction: "nullCheck",
                                errMessage: "Please select an origin stage.",
                                path: [
                                    {name: LessonLearned.buildId(), type: "static"},
                                    {name: LessonLearned.columnSchema.originStage, type: "static"},
                                    {name: LessonLearned.columnSchema.oid, type: "eval"}
                                ]
                            }, {
                                checkColumn: LessonLearned.columnSchema.submittedByEmployeeId,
                                checkFunction: "nullCheck",
                                errMessage: "Please select an origin stage.",
                                path: [
                                    {name: LessonLearned.buildId(), type: "static"},
                                    {name: LessonLearned.columnSchema.submittedByEmployeeId, type: "static"},
                                    {name: LessonLearned.columnSchema.oid, type: "eval"}
                                ]
                            }]
                        }, {
                            changeLog: payload.lessonLearnedInstancesChangeLog,
                            checks: []
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetLessonLog()
                    mergeAndResetLessonInstanceLog()
                    setIsCreating(false)
                }}
                showDelete={!isCreating && selectedLessonId}
                onDelete={deleteLesson}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        lessonLearnedChangeLog: prepareLessonLog(),
                        lessonLearnedInstancesChangeLog: prepareLessonInstanceLog()
                    }
                }}
                style={{minWidth: "800px"}}
            >
                {selectedLesson ?
                    <LessonLearnedForm
                        key={selectedLessonId}
                        lesson={selectedLesson}
                        selectedProject={selectedPlant}

                        lessonInstances={filteredLessonInstances}
                        businessCategories={businessCategories}
                        employees={employees}
                        projects={projects}
                        risks={risks}
                        opportunities={opportunities}
                        
                        updateLesson={updateLessonLogProxy}
                        updateLessonInstance={updateLessonInstanceLog}
                        addLessonInstance={() => {
                            const newLessonInstanceRecord = makeLessonInstanceRecord(selectedLessonId)
                            addToLessonInstanceLog(newLessonInstanceRecord)
                        }}
                        deleteLessonInstance={deleteFromLessonInstanceLog}
                        errors={errors}
                        removeErrors={removeErrors}
                    /> :
                    <div className="flow-vertical" style={{textAlign: "center"}}>Please select a Lesson.</div>
                }
            </ViewPanel>
        </div>
    )
}

const LessonLearnedForm = ({selectedProject, lesson, lessonInstances, businessCategories, employees, projects, risks, opportunities, updateLesson, updateLessonInstance, addLessonInstance, deleteLessonInstance, errors, removeErrors}) => {

    const hasResolutionDate = Boolean(lesson[LessonLearned.columnSchema.dateResolved])
    const [showResolutionDate, setShowResolutionDate] = useState(hasResolutionDate)

    const categoryId = lesson[LessonLearned.columnSchema.categoryId]
    const businessCategory = businessCategories.find(x => x[BusinessCategory.columnSchema.oid]===categoryId) || {}

    const employeeId = lesson[LessonLearned.columnSchema.submittedByEmployeeId]
    const employee = employees.find(x => x[Employee.columnSchema.employeeId]===employeeId)

    const statusErrorPath = [LessonLearned.buildId(), LessonLearned.columnSchema.status, lesson[LessonLearned.columnSchema.oid]]
    const statusError = errors.get(...statusErrorPath)
    const statusProps = statusError ? {error: true, helperText: statusError.getMessage()} : {}

    const originStageErrorPath = [LessonLearned.buildId(), LessonLearned.columnSchema.originStage, lesson[LessonLearned.columnSchema.oid]]
    const originStageError = errors.get(...originStageErrorPath)
    const originStageProps = originStageError ? {error: true, helperText: originStageError.getMessage()} : {}
    
    const submittedByErrorPath = [LessonLearned.buildId(), LessonLearned.columnSchema.submittedByEmployeeId, lesson[LessonLearned.columnSchema.oid]]
    const submittedByError = errors.get(...submittedByErrorPath)
    const submittedByProps = submittedByError ? {error: true, helperText: submittedByError.getMessage()} : {}


    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Lesson Learned"
            />
            <div className="body flow-horizontal">
                <div className="flow-vertical" style={{marginRight: "25px", flexGrow: 1}}>
                    <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                        <PalantirTextField
                            label="Title"
                            value={lesson[LessonLearned.columnSchema.title]}
                            onChange={(x) => updateLesson({[LessonLearned.columnSchema.title]: x})}
                            style={{marginRight: "15px", marginBottom: "15px", flexGrow: 1, minWidth: "150px"}}
                        />
                        <PalantirTextField
                            label="Lesson ID"
                            value={lesson[LessonLearned.columnSchema.oid]}
                            disabled
                            style={{marginRight: "15px", marginBottom: "15px", flexGrow: 1, minWidth: "150px"}}
                        />
                        <PalantirTextField
                            label="Project ID"
                            value={lesson[LessonLearned.columnSchema.projectId]}
                            disabled
                            style={{marginRight: "15px", marginBottom: "15px", flexGrow: 1, minWidth: "150px"}}
                        />
                    </div>
                    <PalantirDispatchedTextField
                        label="Issue Description"
                        value={lesson[LessonLearned.columnSchema.issueDescription]}
                        onChange={(x) => updateLesson({[LessonLearned.columnSchema.issueDescription]: x})}
                        multiline
                        rows={5}
                        variant="filled"
                        style={{marginBottom: "20px", flexGrow: 1, minWidth: "250px"}}
                    />
                    <PalantirDispatchedTextField
                        label="Resolution Details"
                        value={lesson[LessonLearned.columnSchema.resolutionDescription]}
                        onChange={(x) => updateLesson({[LessonLearned.columnSchema.resolutionDescription]: x})}
                        multiline
                        rows={5}
                        variant="filled"
                        style={{marginBottom: "20px", flexGrow: 1, minWidth: "250px"}}
                    />
                </div>
                <div className="flow-vertical fields" style={{flexShrink: 0, paddingRight: "15px"}}>
                    <PalantirSelector
                        label="Status"
                        value={lesson[LessonLearned.columnSchema.status]}
                        items={LessonLearned.options.status}
                        onChange={(x) => {
                            updateLesson({[LessonLearned.columnSchema.status]: x})
                            removeErrors(statusErrorPath)
                        }}
                        style={{marginBottom: "15px"}}
                        {...statusProps}
                    />
                    <PalantirSelector
                        label="Origin Stage"
                        value={lesson[LessonLearned.columnSchema.originStage]}
                        items={LessonLearned.options.impactStage}
                        onChange={(x) => {
                            updateLesson({[LessonLearned.columnSchema.originStage]: x})
                            removeErrors(originStageErrorPath)
                        }}
                        style={{marginBottom: "15px"}}
                        {...originStageProps}
                    />
                    <PalantirSelector
                        label="Impact Stage"
                        value={lesson[LessonLearned.columnSchema.impactStage]}
                        items={LessonLearned.options.impactStage}
                        onChange={(x) => updateLesson({[LessonLearned.columnSchema.impactStage]: x})}
                        style={{marginBottom: "15px"}}
                    />
                    <PalantirAutocomplete
                        label="Category"
                        value={{label: businessCategory[BusinessCategory.columnSchema.name], id: categoryId}}
                        options={businessCategories.map(category => {
                            return {
                                label: category[BusinessCategory.columnSchema.name], id: category[BusinessCategory.columnSchema.oid]
                            }
                        })}
                        onUpdate={(value) => updateLesson({[LessonLearned.columnSchema.categoryId]: value.id})}
                        style={{flexGrow: 0, marginBottom: "15px"}}
                    />
                    <PalantirDatePicker
                        label="Date Identified"
                        value={lesson[LessonLearned.columnSchema.dateIdentified]}
                        onChange={(x) => updateLesson({[LessonLearned.columnSchema.dateIdentified]: x})}
                        style={{marginBottom: "15px"}}
                    />
                    
                    {showResolutionDate && <PalantirDatePicker
                        label="Date Resolved"
                        value={lesson[LessonLearned.columnSchema.dateResolved]}
                        onChange={(x) => updateLesson({[LessonLearned.columnSchema.dateResolved]: x})}
                        style={{marginBottom: "15px"}}
                    />}
                    <Checkbox
                        label="No resolution date"
                        checked={!showResolutionDate}
                        onChange={(e) => {
                            const checked = e.currentTarget.checked
                            setShowResolutionDate(!checked)
                            if (checked) updateLesson({[LessonLearned.columnSchema.dateResolved]: null})
                        }}
                        style={{marginBottom: "10px"}}
                    />
                    <PalantirAutocomplete
                        label="Submitted By"
                        value={{label: employee ? Employee.buildFullName(employee) : "", id: employeeId}}
                        options={employees.map(employee => {
                            return {
                                label: Employee.buildFullName(employee), id: employee[Employee.columnSchema.employeeId]
                            }
                        })}
                        onUpdate={(value) => {
                            updateLesson({[LessonLearned.columnSchema.submittedByEmployeeId]: value.id})
                            removeErrors(submittedByErrorPath)
                        }}
                        style={{flexGrow: 0, marginBottom: "15px"}}
                        InputProps={{...submittedByProps}}
                    />
                </div>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "100px 140px 180px 200px", gridTemplateRows: "30px", rowGap: "15px", columnGap: "20px", marginLeft: "30px", marginBottom: "20px"}}>
                <div style={{gridRow: 1, gridColumn: 1}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>ID</Text></div>
                <div style={{gridRow: 1, gridColumn: 2}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Instance Type</Text></div>
                <div style={{gridRow: 1, gridColumn: 3}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Project</Text></div>
                <div style={{gridRow: 1, gridColumn: 4}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Instance</Text></div>
                {lessonInstances.map((x, idx) => {
                    return (
                        <LessonInstance
                            idx={idx+2}
                            instance={x}
                            selectedProjectIdInView={selectedProject ? selectedProject[Plant.columnSchema.plantId] : null}
                            updateLessonInstance={updateLessonInstance}
                            deleteLessonInstance={() => deleteLessonInstance(x)}
                            projects={projects}
                            risks={risks}
                            opportunities={opportunities}
                        />
                    )
                })}
            </div>
            <AddFloaterButtonWithPrompt
                onClick={addLessonInstance}
                labelContent="Add instance"
                style={{marginLeft: "22px", marginBottom: "15px"}}
            />
        </div>
    )
}

const LessonInstance = ({idx, selectedProjectIdInView, instance, updateLessonInstance, deleteLessonInstance, projects, risks, opportunities}) => {

    const instanceType = instance[LessonLearnedInstances.columnSchema.instanceTable]
    const instanceId = instance[LessonLearnedInstances.columnSchema.instanceId]

    const [selectedProjectId, setSelectedProjectId] = useState(() => {
        // If an instance is selected, init to the instances project
        // else init to the selected project if applicable or none
        if (instanceId) {
            if (instanceType==="risk") {
                const instance = risks.find(x => x[RiskRegister.columnSchema.oid]===instanceId) || {}
                return instance[RiskRegister.columnSchema.projectId] || null
            }
            else if (instanceType==="opportunity") {
                const instance = opportunities.find(x => x[OpportunityRegister.columnSchema.oid]===instanceId) || {}
                return instance[OpportunityRegister.columnSchema.projectId] || null
            }
        }
        else return (selectedProjectIdInView ? selectedProjectIdInView : null)
    })
    const selectedProject = projects.find(x => x[Plant.columnSchema.plantId]===selectedProjectId)
    //const selectedProjectId = selectedProject ? selectedProject[Plant.columnSchema.plantId] : null
    const selectedProjectName = selectedProject ? selectedProject[Plant.columnSchema.plantName] : null

    let lessonInstance = {}
    let lessonInstances = []
    let lessonInstanceIdCol
    let lessonInstanceLabelCol
    if (instanceType==="risk") {
        lessonInstances = risks
        if (selectedProjectId) lessonInstances = lessonInstances.filter(x => x[RiskRegister.columnSchema.projectId]===selectedProjectId)
        lessonInstance = risks.find(x => x[RiskRegister.columnSchema.oid]===instanceId) || {}
        lessonInstanceIdCol = RiskRegister.columnSchema.oid
        lessonInstanceLabelCol = RiskRegister.columnSchema.riskName
    }
    else if (instanceType==="opportunity") {
        lessonInstances = opportunities
        if (selectedProjectId) lessonInstances = lessonInstances.filter(x => x[OpportunityRegister.columnSchema.projectId]===selectedProjectId)
        lessonInstance = opportunities.find(x => x[OpportunityRegister.columnSchema.oid]===instanceId) || {}
        lessonInstanceIdCol = OpportunityRegister.columnSchema.oid
        lessonInstanceLabelCol = OpportunityRegister.columnSchema.opportunityName
    }
    

    return (
        <>
            <DeleteFloaterButtonWithPrompt
                onClick={deleteLessonInstance}
                style={{gridColumn: 1, gridRow: idx, position: "relative", left: "-18px"}}
            />
            <PalantirTextField
                value={instance[LessonLearnedInstances.columnSchema.oid]}
                disabled
                style={{gridColumn: 1, gridRow: idx}}
            />
            <PalantirSelector
                value={instance[LessonLearnedInstances.columnSchema.instanceTable]}
                items={LessonLearnedInstances.options.instanceTable}
                onChange={(x) => updateLessonInstance(instance, {
                    [LessonLearnedInstances.columnSchema.instanceTable]: x,
                    [LessonLearnedInstances.columnSchema.instanceId]: null,
                })}
                style={{gridColumn: 2, gridRow: idx, minWidth: "60px"}}
            />
            <PalantirAutocomplete
                value={{label: selectedProjectName, id: selectedProjectId}}
                options={projects.map(project => {
                    return {
                        label: project[Plant.columnSchema.plantName], id: project[Plant.columnSchema.plantId], meta: project
                    }
                })}
                onUpdate={(value) => setSelectedProjectId(value.id)}
                disabled={selectedProjectIdInView ? true : false}
                style={{gridColumn: 3, gridRow: idx}}
            />
            <PalantirAutocomplete
                value={{label: lessonInstance[lessonInstanceLabelCol], id: instanceId}}
                options={lessonInstances.map(lessonInstance => {
                    return {
                        label: lessonInstance[lessonInstanceLabelCol], id: lessonInstance[lessonInstanceIdCol]
                    }
                })}
                onUpdate={(value) => updateLessonInstance(instance, {[LessonLearnedInstances.columnSchema.instanceId]: value.id})}
                style={{gridColumn: 4, gridRow: idx}}
            />
        </>
    )
}