import './AppNavbar.css'
import { Avatar, Image, Text } from '@mantine/core'
import { PalantirPopMenu, PalantirPopMenuItem } from '../../components/menuv2/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { LinkWithSParams } from '../../components/navigation/Link'
import { prefixPathWithAPIDomain } from '../../utils/image'


export const AppNavbar = ({authServices}) => {

    const getUserAvatarContent = () => {
        const firstName = (authServices.user?.first_name || "").slice(0,1)
        const lastName = (authServices.user?.last_name || "").slice(0,1)
        if (firstName && lastName) return firstName+lastName
        else if (firstName) return firstName
        else if (lastName) return lastName
        else return ""
    }

    return (
        <div className="flow-horizontal app-nav">
            <LinkWithSParams to="/">
                <Image src={prefixPathWithAPIDomain("/static/arevon_logo_large.png")} className="logo" />
            </LinkWithSParams>
            
            <div style={{borderLeft: "solid rgb(193, 193, 193) 1px", height: "20px", margin: "0 12px 0 8px"}}></div>

            <Image src={prefixPathWithAPIDomain("/static/palantir-logo.png")} className="palantir-logo" />
            <Text weight={500} size="sm" style={{color: "#FFD700", marginLeft: "6px"}}>Palantir</Text>

            <div className="align-right">
                <PalantirPopMenu control={<Avatar size="sm" radius="lg" className="user-avatar">{getUserAvatarContent()}</Avatar>}>
                    <PalantirPopMenuItem
                        icon={<FontAwesomeIcon icon={faRightFromBracket} size="sm" className="fa-icon" />}
                        onClick={authServices.logout}
                    >
                        Logout
                    </PalantirPopMenuItem>
                </PalantirPopMenu>
            </div>
        </div>
    )
}