
export const HelpProjectEquipmentEPCContract = () => {
    return (
        <div className="palantir-helpnote">
            <p>Each equipment group can be associated with an existing EPC Contract.</p>
            <p>This is particularly useful when there are multiple EPC Contracts, as each contract may apply to different equipment groups.</p>
        </div>
    )
}

export const HelpInverterEquipmentITCRate = () => {
    return (
        <div className="palantir-helpnote">
            <p>The ITC rate at the time which the equipment was <a href="https://en.wikipedia.org/wiki/Safe_harbor_(law)" target="_blank">Safe Harbored.</a></p>
        </div>
    )
}

export const HelpContractOwnerProvidedEquipment = () => {
    return (
        <div className="palantir-helpnote">
            <p>Whether the contract provides for <i>any type</i> of owner provided equipment.</p>
            <p>If the contract only specifies certain types of owner provided equipment (eg. Inverters and Modules), you may specify this in the corresponding Project Equipment Group.</p>
        </div>
    )
}

export const HelpDryBulbTemp = () => {
    return (
        <div className="palantir-helpnote">
            Used to derive <a href="http://ashrae-meteo.info/v2.0/" target="__blank">http://ashrae-meteo.info/v2.0/</a>
        </div>
    )
}
export const HelpRainfall = () => {
    return (
        <div className="palantir-helpnote">
            <p>Verify max 100yr 24hrs and annual rainfall at site.</p>
            <a href="https://hdsc.nws.noaa.gov/hdsc/pfds/pfds_map_cont.html" target="__blank">https://hdsc.nws.noaa.gov/hdsc/pfds</a>
        </div>
    )
}
export const HelpSnowLoad = () => {
    return (
        <div className="palantir-helpnote">
            <p>Determine Ground Snow Load per ASCE 7‐10</p>
            <a href="http://windspeed.atcouncil.org" target="__blank">http://windspeed.atcouncil.org</a>
        </div>
    )
}
export const HelpWindLoad = () => {
    return (
        <div className="palantir-helpnote">
            <p>Basic Wind Speed per ASCE 7‐10, Sec 26.5.1 and IBC 2012, Sec 1609.3</p>
            <p><a href="http://windspeed.atcouncil.org" target="__blank">http://windspeed.atcouncil.org</a></p>
            <p><a href="https://asce7hazardtool.online/" target="__blank">https://asce7hazardtool.online</a></p>
        </div>
    )
}
export const HelpSeismic = () => {
    return (
        <div className="palantir-helpnote">
            <p>Determine Risk Category per ASCE 7-10, Table 1.5-1 and IBC 2012, Sec/Table  1604.5. GM facilities are risk/occupancy category I, RM facilities category II., </p>
            <a href="https://seismicmaps.org/" target="__blank">https://seismicmaps.org/</a>
        </div>
    )
}
export const HelpFlood = () => {
    return (
        <div className="palantir-helpnote">
            <p>The Federal Emergency Management Agency (FEMA) groups Flood Zones</p>
            <a href="http://msc.fema.gov/portal" target="__blank">http://msc.fema.gov/portal</a>
        </div>
    )
}
export const HelpWetlands = () => {
    return (
        <div className="palantir-helpnote">
            <p>
                The Clean Water Act (CWA) per EPA & US Army Corp of Engineers classifies wetlands as “Waters of the US” to include swamps, marshes, bogs, etc.
                Refer to site map with delineated wetlands in Phase 1 ESA, or US Fish and Wildlife, National Wetlands Inventory.
            </p>
            <a href="https://www.fws.gov/program/national-wetlands-inventory/wetlands-mapper" target="__blank">https://www.fws.gov/program/national-wetlands-inventory/wetlands-mapper</a>
        </div>
    )
}
export const HelpBiological = () => {
    return (
        <div className="palantir-helpnote">
            <p>
                The Governmental Authority may require a Biological Permit/Study if threatened or endangered species (e.g. bog turtles) are known to inhabit the site area.
                Identify risk of on-site seasonal nesting by migratory birds.
                Refer to USFW Search <a href="https://www.fws.gov/program/endangered-species/species" target="__blank">https://www.fws.gov/program/endangered-species/species</a>
            </p>
        </div>
    )
}

export const HelpProjectCOD = () => {
    return (
        <div className="palantir-helpnote">
            <p className="header-2">Memo Approved COD</p>
            <p>The Memo Approved COD (also can be referred to as the Investment Case COD) is the COD that was approved by investors when the project was underwritten.</p>
            <p className="header-2">P10 COD</p>
            <p>Optimistic expectation of when the project wil become operational.</p>
            <p className="header-2">P50 COD</p>
            <p>Reasonable expectation of when the project wil become operational.</p>
            <p className="header-2">P90 COD</p>
            <p>Conservative expectation of when the project wil become operational.</p>
            <p className="header-2">Actual COD</p>
            <p>The Actual COD is the date at which the site became operational.</p>
            <p className="header-2">Contractual COD</p>
            <p>
                The Contractual COD is specified in the PPA agreement.
                The owner starts accruing LDs if the project does not achieve COD by this date.
                This is located in the Offtake Contract.
            </p>
            <p>This is located in the Offtake Contract.</p>
        </div>
    )
}
export const HelpProjectFinancialClose = () => {
    return (
        <div className="palantir-helpnote">
            <p className="header-2">Memo Approved FC</p>
            <p className="header-2">P10 FC</p>
            <p>Optimistic expectation of when the project will secure financing. Generally should be close to the corresponding FNTP date.</p>
            <p className="header-2">P50 FC</p>
            <p>Reasonable expectation of when the project will secure financing. Generally should be close to the corresponding FNTP date.</p>
            <p className="header-2">P90 FC</p>
            <p>Conservative expectation of when the project will secure financing. Generally should be close to the corresponding FNTP date.</p>
            <p className="header-2">Actual FC</p>
            <p>The Actual Financial Close Date is the date at which financing is secured for the project.</p>
        </div>
    )
}

export const HelpProjectFNTP = () => {
    return (
        <div className="palantir-helpnote">
            <p className="header-2">Current FNTP</p>
            <p>The current expectation of when FNTP will be reached.</p>
            <p className="header-2">Base (Budgeted) FNTP</p>
            <p>Final Notice to Proceed as agreed upon initially with the EPC contractor. Issuing FNTP allows the EPC contractor to proceed with all construction activities.</p>
        </div>
    )
}

export const HelpProjectTermConversion = () => {
    return (
        <div className="palantir-helpnote">
            <p className="header-2">P50 TC</p>
            <p>Reasonable expectation of when the project's construction loan is taken out by permanent financing.</p>
            <p className="header-2">P90 TC</p>
            <p>Conservative expectation of when the project's construction loan is taken out by permanent financing.</p>
        </div>
    )
}

export const HelpProjectStatus = () => {
    return (
        <div className="palantir-helpnote">
            <p>Current status of the Project.</p>
            <p className="header-2">Options</p>
            <strong style={{fontSize: 12}}>Due Diligence</strong>
            <p style={{fontSize: 10}}>Projects under review that are not in Development, Construction, or Operations</p>
            <strong style={{fontSize: 12}}>M&A</strong>
            <p style={{fontSize: 10}}>Placeholder for M&A annual MW targets (for planning purposes only)</p>
            <strong style={{fontSize: 12}}>Greenfield</strong>
            <p style={{fontSize: 10}}>Placeholder for M&A annual MW targets (for planning purposes only)</p>
            <strong style={{fontSize: 12}}>Development</strong>
            <p style={{fontSize: 10}}>Projects in development regardless of stage</p>
            <strong style={{fontSize: 12}}>Construction</strong>
            <p style={{fontSize: 10}}>Projects that have reached NTP and are in construction</p>
            <strong style={{fontSize: 12}}>Operating</strong>
            <p style={{fontSize: 10}}>Projects that have reached COD and are in operations</p>
            <strong style={{fontSize: 12}}>Sold - Proposed</strong>
            <p style={{fontSize: 10}}>Projects proposed for sale in the current year</p>
            <strong style={{fontSize: 12}}>Sold</strong>
            <p style={{fontSize: 10}}>Projects that have been sold</p>
            <strong style={{fontSize: 12}}>Write Off - Proposed</strong>
            <p style={{fontSize: 10}}>Projects proposed for write off in the current year</p>
            <strong style={{fontSize: 12}}>Write Off</strong>
            <p style={{fontSize: 10}}>Projects that have been written off</p>
        </div>
    )
}

export const HelpRiskRegisterBudgetedCostImpact = () => {
    return (
        <div className="palantir-helpnote">
            <p>Estimated or budgeted total cost impact of the risk or opportunity.</p>
            <p className="header-2">Formula</p>
            <p style={{fontSize: 12}}>budgeted_cost_impact = worst_case_cost_impact * (probability / 5)</p>
            <p className="header-2">Cost Impact Rating Thresholds</p>
            <div style={{display: "grid", marginTop: "10px"}}>
                <div style={{gridColumn: 1, gridRow: 1, fontSize: "11px", borderBottom: "solid 1px grey"}}>Budgeted Cost Impact Range</div>
                <div style={{gridColumn: 2, gridRow: 1, fontSize: "11px", borderBottom: "solid 1px grey"}}>Cost Impact Rating</div>
                <div style={{gridColumn: 1, gridRow: 2, fontSize: "11px"}}>$0 - $100,000</div>
                <div style={{gridColumn: 2, gridRow: 2, fontSize: "11px"}}>0</div>
                <div style={{gridColumn: 1, gridRow: 3, fontSize: "11px"}}>$100,000 - $500,000</div>
                <div style={{gridColumn: 2, gridRow: 3, fontSize: "11px"}}>1</div>
                <div style={{gridColumn: 1, gridRow: 4, fontSize: "11px"}}>$500,000 - $1,000,000</div>
                <div style={{gridColumn: 2, gridRow: 4, fontSize: "11px"}}>2</div>
                <div style={{gridColumn: 1, gridRow: 5, fontSize: "11px"}}>$1,000,000 - $2,000,000</div>
                <div style={{gridColumn: 2, gridRow: 5, fontSize: "11px"}}>3</div>
                <div style={{gridColumn: 1, gridRow: 6, fontSize: "11px"}}>$2,000,000 - $5,000,000</div>
                <div style={{gridColumn: 2, gridRow: 6, fontSize: "11px"}}>4</div>
                <div style={{gridColumn: 1, gridRow: 7, fontSize: "11px"}}>$5,000,000+</div>
                <div style={{gridColumn: 2, gridRow: 7, fontSize: "11px"}}>5</div>
            </div>
        </div>
    )
}